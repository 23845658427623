// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-cookies-index-tsx": () => import("./../../../src/pages/cookies/index.tsx" /* webpackChunkName: "component---src-pages-cookies-index-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-tos-index-tsx": () => import("./../../../src/pages/tos/index.tsx" /* webpackChunkName: "component---src-pages-tos-index-tsx" */),
  "component---src-templates-category-index-tsx": () => import("./../../../src/templates/Category/index.tsx" /* webpackChunkName: "component---src-templates-category-index-tsx" */),
  "component---src-templates-fixed-attr-index-tsx": () => import("./../../../src/templates/FixedAttr/index.tsx" /* webpackChunkName: "component---src-templates-fixed-attr-index-tsx" */),
  "component---src-templates-no-card-index-tsx": () => import("./../../../src/templates/NoCard/index.tsx" /* webpackChunkName: "component---src-templates-no-card-index-tsx" */),
  "component---src-templates-random-attr-index-tsx": () => import("./../../../src/templates/RandomAttr/index.tsx" /* webpackChunkName: "component---src-templates-random-attr-index-tsx" */)
}

